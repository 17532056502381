// Imports
// --------------------------------------------------
@import "resources";
// --------------------------------------------------

.main-container-wrap {
    padding:0 !important;
}

//NOVINKY SLIDER - homepage/_novinky_slider.html.twig
.novinky-slider-wrap {
    margin-top:5rem;
    margin-bottom:2rem;

    .item {
        width:33.3333%;
        padding-top:3rem;
        padding-bottom:3rem;
        transform:scale(0.65);
        transition:transform 0.2s linear;
        z-index:50;

        &.is-selected {
            transform: scale(1.1);
            z-index:100;
            opacity:1;
            .img {
                .sticky-text {
                    opacity:1;
                }
                .name {
                    opacity:1;
                }
            }
        }
        .img {
            position:relative;
            padding:3em 4em;
            display:block;
            color:inherit;
            text-decoration:none;

            .name {
                display:flex;
                flex-direction: column;
                justify-content: center;
                position:absolute;
                width:100%;
                height:100%;
                max-width:200px;
                overflow:hidden;
                text-overflow:ellipsis;
                top:0;
                left:0;
                bottom:0;
                right:0;
                margin:auto;
                z-index:30;
                text-align:center;
                line-height:1.5em;
                font-size:0.875em;
                opacity:0;
                transition:$global_transition;

                span span {
                    font-weight:700;
                    color:$white;
                    padding:0.25em 0.5em;
                    background:rgba(141,160,124,0.75);
                    display:inline-block
                }
            }
            .sticky-text-wrap {
                width:100%;
                height:100%;
                left:0;
                top:0;
                position:absolute;
                z-index:20;
                display:flex;
                flex-direction: column;
                justify-content: center;
            }
            .sticky-text {
                position:absolute;
                display:flex;
                flex-direction:column;
                justify-content: center;
                width:0;
                height:0;
                font-size:0.75em;
                transition:$global_transition;
                opacity:0;
                text-shadow:1px 1px 2px #fff;

                span {
                    display:block;
                    width:10rem;
                    position:absolute;
                }
                &.t1 {
                    left:36%;
                    top:4%;

                    span {
                        bottom:0;
                        right:100%;
                        text-align:right;
                    }
                }
                &.t2 {
                    right:36%;
                    top:4%;

                    span {
                        bottom:0;
                        left:100%;
                    }
                }
                &.t3 {
                    right:20%;
                    top:0;
                    bottom:0;
                    margin:auto;
                }
                &.t4 {
                    bottom:4%;
                    right:36%;

                    span {
                        top:0;
                    }
                }
                &.t5 {
                    bottom:4%;
                    left:36%;

                    span {
                        text-align:right;
                        right:100%;
                        top:0
                    }
                }
                &.t6 {
                    top:0;
                    bottom:0;
                    left:20%;
                    margin:auto;

                    span {
                        text-align:right;
                        right:100%;
                    }
                }
            }
            img {
                position:relative;
                z-index:5;
                display:block;
                max-width:50%;
                width:100%;
                height:auto;
                margin:0 auto;
            }
            &:before {
                position:absolute;
                content:'';
                width:13rem;
                height:13rem;
                border-radius:1000px;
                /* Permalink - use to edit and share this gradient: https://colorzilla.com/gradient-editor/#ffffff+0,ffffff+50,ffffff+100&1+0,0+100 */
                background: -moz-radial-gradient(center, ellipse cover,  rgba(255,255,255,1) 0%, rgba(255,255,255,0.5) 50%, rgba(255,255,255,0) 100%); /* FF3.6-15 */
                background: -webkit-radial-gradient(center, ellipse cover,  rgba(255,255,255,1) 0%,rgba(255,255,255,0.5) 50%,rgba(255,255,255,0) 100%); /* Chrome10-25,Safari5.1-6 */
                background: radial-gradient(ellipse at center,  rgba(255,255,255,1) 0%,rgba(255,255,255,0.5) 50%,rgba(255,255,255,0) 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
                filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#ffffff', endColorstr='#00ffffff',GradientType=1 ); /* IE6-9 fallback on horizontal gradient */

                left:0;
                top:0;
                bottom:0;
                right:0;
                margin:auto;
                display:block;
                z-index:2;
            }
            svg {
                position:absolute;
                z-index:1;
                height:100%;
                width:auto;
                top:0;
                bottom:0;
                right:0;
                left:0;
                margin:auto;
                fill:#d3c47f;
            }
        }
    }
    .flickity-prev-next-button {
        width:2.625rem;
        height:2.625rem;
        position:absolute;
        background:$c-btn-green;
        border-radius:10rem;
        transition:$global_transition;

        &:hover {
            background:$c-btn-green-hover;
        }
        svg {
            position:absolute;
            display:block;
            width:0.75rem;
            height:0.75rem;
            left:0;
            right:0;
            bottom:0;
            top:0;
            margin:auto;
            fill:#fff;
        }
        &.previous {
            left:2%;
        }
        &.next {
            right:2%;
        }
    }
    @include media-breakpoint-down(md) {
        margin-bottom:0;
        margin-top:2.5rem;

        .item {
            width:100%;
            padding-top:0;
            padding-bottom:0;

            &.is-selected {
                transform:scale(1);
            }
            .img {
                display:flex;
                flex-direction: column;
                padding-top:1em;
                padding-bottom:1em;

                .sticky-text-wrap {
                    order:3;
                    position:static;

                    .sticky-text {
                        position:static !important;
                        width:auto;
                        height:auto;
                        display:block;
                        margin:0;
                        margin-bottom:0.5em;
                        font-size:1em;
                        padding-left:0.5em;

                        span {
                            text-align:left;
                            margin:0;
                            width:100% !important;
                            position:relative;
                            left:0;
                            right:auto;
                            top:auto;
                            bottom:auto;

                            &:before {
                                content:'• ';
                            }
                        }
                    }
                }
                img {
                    order:1;
                    max-width:80%;
                }
                svg {
                    display:none;
                }
                .name {
                    order:2;
                    position:static;
                    margin-bottom:0.75em;
                    max-width:none;
                    font-size:1.2em;

                    span span {
                        padding:0.5em 0.75em;
                    }
                }
            }
        }
    }

}

//CTA PARTNER - homepage/_cta_partner.html.twig

.cta-wrap {
    margin-top:5rem;
    .cta-message {
        margin-top:2rem;
    }
    .cta-content {
        align-items: center;
        justify-content: space-between;
    }
    h2 {
        font-size:2.5em;
        font-weight:$font-weight-medium;
        margin-bottom:1em;

        span {
            font-size:1.25em;
            font-weight:$font-weight-bold;
        }
    }
    &.cta-spotrebitel-wrap {
        .cta-right-part {
            order:1;
            margin-right:16%;
            margin-left:0;
            justify-content: flex-end;

            .cta-img-wrap {
                right:13rem;
                left:0;
            }
            .cta-img {
                transform:rotate(-15deg);
            }
            .triangle {
                margin-left:-1px;
                margin-right:0;
                right:auto;
                left:100%;
            }
        }
        .cta-left-part {
            order:2;
            margin-right:15%;
            margin-left:0;
            padding-left:2em;
            padding-right:15px;
        }
        .btn-hexa-register {
            transform:translateX(90%);
        }
        @media (max-width:1660px) {
            .btn-hexa-register {
                transform:translateX(8%);
            }
            .cta-right-part {
                margin-right:22.5rem;
            }
            .cta-left-part {
                margin-right:15px;
            }
        }
        @include media-breakpoint-down(md) {
            .cta-left-part {
                order:1;
            }
            .cta-right-part {
                margin-right:0;
                order:2;
            }
            .btn-hexa-register {
                transform:translateX(0);
            }
        }
    }
    .cta-right-part {
        background: $c-black;
        position:relative;
        padding-top:2rem;
        padding-bottom:2rem;
        display:flex;
        flex-direction:row;
        justify-content: flex-start;

        .cta-img-wrap {
            position:absolute;
            height:100%;
            width:37.5em;
            overflow: hidden;
            right:0;
            top:0;
            bottom:0;
        }
        .cta-img {
            fill:$c-green-black;
            height:37.5rem;
            width:auto;
            display:block;
            right:-5rem;
            top:0;
            bottom:0;
            margin:auto;
            position:absolute;
            transform:rotate(15deg);
        }
        .triangle {
            display:block;
            position:absolute;
            width:auto;
            height:100%;
            right:100%;
            top:0;
            bottom:0;
            margin-right:-1px;
            fill:$c-black;
        }
    }
    .btn-hexa-register {
        position:relative;
        color:#fff;
        display:flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        width:23.75em;
        height:23.75em;
        text-decoration:none;
        transform: translateX(-90%);
        z-index:10;

        .outer {
            display:block;
            text-align:center;
            position:relative;
            z-index:10;

            .inner {
                padding-top:0.5em;
                padding-bottom:0.5em;
                letter-spacing: 0.25em;
                line-height:1;
                font-size:1.5em;
                text-transform:uppercase;
                font-weight:$font-weight-bold;
                display:inline-block;
                border-top:3px solid $white;
                border-bottom:3px solid $white;
            }
        }
        svg {
            position:absolute;
            width:100%;
            height:auto;
            left:0;
            top:0;
            z-index:5;

            .hovering {
                fill:$c-btn-green;
                transition:$global_transition;
            }
        }
        &:hover {
            svg {
                .hovering {
                    fill:$c-btn-green-hover;
                }
            }
        }
    }

    .cta-disclaimer {
        margin-top:1em;
    }
    .cta-left-part {
        padding-left:15px;
        padding-right:2em;
        width:36%;
        margin-left:15%;
    }
    .cta-right-part {
        padding-left:15px;
        padding-right:15px;
        width:33%;
        margin-left:16%;
    }
    @media (max-width:1900px) {
        .cta-right-part {
            width:25%;
            margin-left:24%;
        }
    }
    @media (max-width:1660px) {
        .cta-left-part {
            flex:1;
        }
        .cta-right-part {
            width:100px;
            display:flex;
            flex-direction: column;
            justify-content: center;

            .cta-img-wrap {
                display:none;
            }
        }
    }
    @include media-breakpoint-down(lg) {
        .cta-left-part {
            margin-left:0;
        }
        .cta-right-part {
            margin-left:20em;
        }
    }
    @include media-breakpoint-down(md) {
        margin-top:2.5rem;
        padding-top:3em;
        background:$c-silver-lighter;
        border:1px solid $c-silver-light;

        .cta-left-part, .cta-right-part {
            margin:0;
            width:100%;
            text-align:center;
        }
        .cta-right-part {
            margin-top:2em;
        }
        .cta-right-part .triangle {
            display:none;
        }
        .btn-hexa-register {
            width:auto;
            height:auto;
            position:static;
            transform:none;
            border-radius:$global_border_radius;
            background:$c-btn-green;
            display:inline-block;
            padding:1em 2em;

            &:hover {
                background:$c-btn-green-hover;
            }
            svg {
                display:none;
            }
            .outer {
                .inner {
                    padding:0;
                    display:inline;
                    border:none;
                    line-height:normal;
                    letter-spacing:normal;
                    font-weight:500;
                }
            }
        }
    }
    @include media-breakpoint-down(sm) {
        h2 {
            font-size:2em;
        }
    }
}

// REFERENCES - homepage/_references.html.twig
.references-wrap {
    margin-top:5rem;

    .item {
        padding-top:1em;
        padding-bottom:1em;
        width: 50%;
        text-align:center;
        padding:0 6em;
        filter:blur(5px);

        &.is-selected {
            filter:blur(0);
        }
        img {
            display:block;
            margin:0 auto;
            margin-bottom:1em;
            border-radius: 50%;
        }
        .text {
            margin-bottom:1em;
        }
        .name {
            font-weight:$font-weight-bold;
            color:$c-btn-green;
        }
        p {
            margin:0;
        }
    }
    .flickity-prev-next-button {
        width:2.625rem;
        height:2.625rem;
        position:absolute;
        background:$c-btn-green;
        border-radius:10rem;
        transition:$global_transition;

        &:hover {
            background:$c-btn-green-hover;
        }
        svg {
            position:absolute;
            display:block;
            width:0.75rem;
            height:0.75rem;
            left:0;
            right:0;
            bottom:0;
            top:0;
            margin:auto;
            fill:#fff;
        }
        &.previous {
            left:25%;
        }
        &.next {
            right:25%;
        }
    }
    @include media-breakpoint-down(lg) {
        .item {
            width:75%
        }
        .flickity-prev-next-button {
            &.previous {
                left:10%;
            }
            &.next {
                right:10%;
            }
        }
    }
    @include media-breakpoint-down(md) {
        .item {
            width:100%;
            padding-left:2.5em;
            padding-right:2.5em;
        }
        .flickity-prev-next-button {
            display:none;
        }
    }
}
