// Imports
// --------------------------------------------------
@import "resources";
// --------------------------------------------------

// BANNER SLIDER - homepage/_slider.html.twig
.banner-slider-wrap, .banner-slider-mobile-wrap {
    border-radius:$global_border_radius;

    .slide-item {
        width:100%;
        overflow: hidden;
        border-radius: $global_border_radius;

        a, img {
            display:block;
            width:100%;
            height:auto;
        }
    }
    .flickity-prev-next-button {
        width:2.625rem;
        height:2.625rem;
        position:absolute;
        background:$c-btn-green;
        border-radius:10rem;
        transition:$global_transition;

        &:hover {
            background:$c-btn-green-hover;
        }
        svg {
            position:absolute;
            display:block;
            width:0.75rem;
            height:0.75rem;
            left:0;
            right:0;
            bottom:0;
            top:0;
            margin:auto;
            fill:#fff;
        }
        &.previous {
            left:2em;
        }
        &.next {
            right:2em;
        }
    }
}
.banner-slider-mobile-wrap {
    display:none;
}
@include media-breakpoint-down(sm) {
    .banner-slider-wrap {
        display:none;
    }
    .banner-slider-mobile-wrap {
        display:block;
    }
}
