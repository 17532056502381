// Imports
// --------------------------------------------------
@import "resources";
// --------------------------------------------------

// PRODUCT SLIDER - homepage/ - _product_1st_row.html.twig, _product_2nd_row.html.twig, _product_3rd_row.html.twig
.products-slider-wrap {
    margin-top:3.75rem;

    .product-slider-content {
        padding-bottom:2rem;
        padding-left:2.8125rem;
        padding-right:2.8125rem;
    }
    .product-card-wrap {
        width:25%;
        margin-bottom:0;
        padding-left:15px;
        padding-right:15px;

        .price-wrap {
            margin-bottom:0;
        }
    }
    .flickity-prev-next-button {
        width:2.625rem;
        height:2.625rem;
        position:absolute;
        background:$c-btn-green;
        border-radius:10rem;
        transition:$global_transition;

        &:hover {
            background:$c-btn-green-hover;
        }
        svg {
            position:absolute;
            display:block;
            width:0.75rem;
            height:0.75rem;
            left:0;
            right:0;
            bottom:0;
            top:0;
            margin:auto;
            fill:#fff;
        }
        &.previous {
            left:0;
        }
        &.next {
            right:0;
        }
    }
    .flickity-page-dots {
        bottom:0;
        text-align:left;
        padding-left:5em;
        left:0;

        &:before {
            width:4em;
            height:4px;
            background:$c-green-light;
            border-radius: $border-radius-sm;
            position:absolute;
            content:'';
            left:0;
            top:0;
            bottom:0;
            margin:auto;
        }
        .dot {
            background:$c-green-light;
            width:1em;
            height:1em;
            opacity:0.5;
            transition:$global_transition;

            &.is-selected {
                background:$c-btn-green;
                opacity:1;
            }
        }
    }
}

@include media-breakpoint-down(md) {
    .products-slider-wrap {
        .product-card-wrap {
            width:50%;
        }
    }
}
@include media-breakpoint-down(sm) {
    .products-slider-wrap {
        margin:2rem 0;
    }
}
@include media-breakpoint-down(xs) {
    #slider-product-content {
        margin-left:-15px;
        margin-right:-15px;
    }
    .products-slider-wrap {
        .product-slider-content {
            padding-left:0;
            padding-right:0;
        }
        .flickity-prev-next-button {
            display:none;
        }
        .flickity-page-dots {
            left:15px;
            width:auto;
        }
    }
}
